:root {
  --gap-tiny: 5px;
  --gap-small: 10px;
  --gap-normal: 20px;
  --gap-large: 40px;
  --gap-huge: 80px;

  --colors-plex-primary: #e5a00d;
  --colors-plex-secondary: #282a2d;
  --colors-background-darker: #0C0C0C;
  --colors-background-dark: #191919;
  --colors-background-normal: #212121;
  --colors-background-light: #323232;
  --colors-background-lighter: #585858;
  --colors-background-inverse: #eee;
  --colors-foreground-darker: #999;
  --colors-foreground-dark: #bbb;
  --colors-foreground-normal: #eee;
  --colors-foreground-light: #FFFFFFBF;
  --colors-foreground-lighter: #FFFFFFFF;
  --colors-foreground-inverse: #000;
  --colors-white: #ffffff;

  --font-size-tiny: 10px;
  --font-size-small: 12px;
  --font-size-normal: 14px;
  --font-size-large: 16px;
  --font-size-huge: 18px;
  --font-size-header-small: 20px;
  --font-size-header-normal: 22px;
  --font-size-header-large: 24px;

  --font-family-plex: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif;
  --font-family-plex-circular: "Circular Bold","Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}

html, body, #root {
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;

  color: var(--colors-foreground-normal);
  font-size: var(--font-size-normal);
  font-family: var(--font-family-plex);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  transition: all 0.1s ease-in-out;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

span {
  display: inline-block;
}

a {
  text-decoration: none;
  color: var(--colors-foreground-normal);
}

button {
  cursor: pointer;
  border: 1px solid var(--colors-plex-primary);
}
button.primary {
  background-color: var(--colors-plex-primary);
  color: var(--colors-white);
}
button.primary svg {
  fill: var(--colors-white);
}
button.secondary {
  background-color: transparent;
  color: var(--colors-plex-primary);
}
button.secondary svg {
  fill: var(--colors-plex-primary);
}

input, textarea {
  outline: none;
  border: none;
}
