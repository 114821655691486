.root {
  max-height: 2px;
  width: 100%;
}
.moving {
  width: 100%;
  height: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: var(--colors-plex-primary);
  transform-origin: 0% 50%;
  animation: animation 1s ease-in-out infinite;
}
@keyframes animation {
  0% { transform: translateX(0) scaleX(0); }
  40% { transform: translateX(0) scaleX(0.4); }
  100% { transform: translateX(100%) scaleX(0.5); }
}