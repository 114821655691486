.root {
  position: relative;
  display: flex;
  flex-direction: row;

  border-radius: var(--gap-small);
  background-color: var(--colors-background-darker);
  overflow: hidden;
}

.root img {
  height: calc(
    2 * var(--gap-normal) +
    1 * var(--font-size-huge) * 2 +
    1 * var(--font-size-normal) * 1.5 +
    1 * var(--font-size-normal) * 2
  );
}

.infoPane {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: center;
  padding: 0 var(--gap-normal);
  overflow: hidden;
}

.infoPane .infoPaneTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: var(--gap-small);
}
.infoPaneTitleContainer svg {
  height: calc(var(--font-size-huge) - 3px);
  fill: var(--colors-plex-primary);
  cursor: pointer;
  border-radius: var(--gap-tiny);
  padding: 5px;
}
.infoPaneTitleContainer svg:hover {
  background-color: var(--colors-background-light);
}

.infoPane p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.infoPane {
  font-size: var(--font-size-normal);
  line-height: calc(var(--font-size-normal) * 1.5);
}
.infoPane .text {
  font-size: var(--font-size-huge);
  line-height: calc(var(--font-size-huge) * 2);
}
.infoPane .footer {
  color: var(--colors-foreground-darker);
  line-height: calc(var(--font-size-normal) * 2);
}

.statusContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: var(--gap-small);
}

.statusContainer span + span {
  border-radius: var(--gap-small);
  color: var(--colors-foreground-lighter);
  padding: 0 var(--gap-small);
}

.fulfilled {
  background-color: green;
}
.busy {
  background-color: #ABA000;
}
.pending {
  background-color: blue;
}
.rejected {
  background-color: red;
}

.actionsContainer {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: var(--gap-small);
  padding: var(--gap-small);
}

.actionsContainer svg {
  cursor: pointer;
  width: var(--font-size-header-large);
  height: var(--font-size-header-large);
  fill: none;
  stroke: var(--colors-foreground-darker);
}

.actionsContainer:hover .popupContainer {
  display: flex !important;
}

div.popupContainer {
  position: absolute;
  z-index: 5;
  top: 0;
  right: 100%;
  display: none;
  flex-direction: column;
  gap: var(--gap-tiny);
  background-color: var(--colors-background-dark);
  padding: var(--gap-small);
  border-radius: var(--gap-tiny);
}

div.popupContainer > span {
  padding: 2px var(--gap-small);
  border-radius: var(--gap-small);
  cursor: pointer;
}
