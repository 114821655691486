.root {
  display: flex;
  flex-direction: column;
  gap: var(--gap-normal);
  align-items: center;
  padding-bottom: var(--gap-normal);
}

.requestsList {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: var(--gap-normal);
  column-gap: var(--gap-normal);
}

.filterContainer {
  display: flex;
  flex-direction: row;
  gap: var(--gap-normal);

  width: 100%;
  justify-content: start;
  align-items: center;
}
.filterContainer p {
  font-size: var(--font-size-huge);
}

@media only screen and (min-width: 1000px) {
  .requestsList {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}
