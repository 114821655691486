.root {
  padding: var(--gap-huge) var(--gap-normal) 0 var(--gap-normal);
  display: flex;
  flex-direction: column;
  gap: var(--gap-large);
  align-items: center;
  justify-content: center;
  text-align: center;
}

h1 {
  width: 70%;
}

.cardContainer {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-normal);
}

.cardContainer a {
  display: flex;
}

.card {
  display: flex;
  flex-direction: column;
  gap: var(--gap-normal);
  align-items: center;
  border-radius: var(--gap-tiny);
  cursor: pointer;
  border: 1px solid var(--colors-plex-primary);
  transition: all 0.1s ease-out;
  padding: var(--gap-normal);
  flex: 1;
  max-width: 200px;
  background-color: #00000030;
  font-size: var(--font-size-normal);
}

.card:hover {
  background-color: var(--colors-background-dark);
  transform: scale(1.2);
}

.card svg {
  height: var(--gap-large);
  min-height: var(--gap-large);
  fill: var(--colors-plex-primary);
}

.textContainer {
  flex: 1;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 1200px) {
  .cardContainer {
    gap: var(--gap-large);
  }
  .card {
    padding: var(--gap-large);
    font-size: var(--font-size-large);
    gap: var(--gap-large);
  }
  .card svg {
    height: var(--gap-huge);
  }
}

.latestRequests {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: var(--gap-normal);
}

.latestRequests .requests {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  column-gap: var(--gap-normal);
}
