.appRoot, .root {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
  background-color: var(--colors-background-normal);
  position: relative;
}

.appRoot {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.appRootMain {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 0;
  height: 1px;
}

.nav {
  width: 300px;
  max-height: 100%;
}

.content {
  flex: 1;
  overflow-y: scroll;
  margin-top: var(--gap-small);
  margin-right: var(--gap-normal);
  margin-left: var(--gap-small);
  padding-bottom: var(--gap-normal);
}

.authWindow {
  position: fixed;
  z-index: 3;
  height: 100%;
  width: 100%;
  background-color: #000000BB;
  backdrop-filter: blur(3px);
}

.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.notificationsContainer {
  position: absolute;
  z-index: 11;
  right: 0;
  bottom: 0;
  width: 30%;
  max-width: 600px;
  min-width: 300px;
}
