.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  position: relative;
  display: flex;
  gap: var(--gap-small);
  align-items: center;

  font-size: var(--font-size-huge);
  padding: var(--gap-small) var(--gap-normal);
  border-radius: calc((2 * var(--gap-small) + var(--font-size-huge)) / 2);
}

.iconContainer {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
}

.iconContainer.left {
  left: var(--gap-small);
}

.iconContainer.right {
  right: var(--gap-small);
}

.iconContainer svg {
  height: calc(100% - 2*var(--gap-small));
}

.iconContainer.loading svg {
  animation: spin 0.7s ease-out infinite;
  transform: rotate(45deg);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% {  transform: rotate(359deg); }
}

.spacer {
  width: var(--gap-small);
}

.wave {
  background: linear-gradient(
    to right,
    rgba(0,0,0,0) 0%,
    rgba(0,0,0,0.25) 50%,
    rgba(0,0,0,0) 51%,
    rgba(0,0,0,0.25) 100%
  );
  background-repeat: repeat-x;
  animation: wave 1s linear infinite;
  background-size: 200%;
  background-position: right;
}

@keyframes wave {
  from { background-position: right; }
  to { background-position: left; }
}